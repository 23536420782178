import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { get } from '../../lib/nodash';
import { Box, Heading } from 'grommet';

import ButtonAddToCart from '../ButtonAddToCart';
import NativeQuantitySelector from './NativeQuantitySelector';

import { getVariantPrice } from '../../lib/product';
import useBuybarUi from './useBuybarUi';

const ProductBuyOptions = ({ variant, maxQty, productTitle }) => {
  const [quantity, setQuantity] = useState(1);
  const { buybarRef, isAtTop } = useBuybarUi();

  return (
    <Box
      direction="row"
      border={{ side: 'all', size: 'small' }}
      align="center"
      background={isAtTop ? 'transparent' : 'white'}
      ref={buybarRef}
    >
      {productTitle && !isAtTop && (
        <Box
          pad="medium"
          flex={{ grow: 1 }}
          border={{ side: 'right', size: 'small' }}
          fill="vertical"
        >
          <Heading level={5}>{productTitle}</Heading>
        </Box>
      )}
      <NativeQuantitySelector
        quantity={quantity}
        setQuantity={setQuantity}
        maxQty={maxQty}
      />
      <ButtonAddToCart
        variantId={get('shopifyId', variant)}
        quantity={parseInt(quantity)}
        label={`${getVariantPrice(variant)} Add to Cart`}
        size="small"
      />
    </Box>
  );
};

ProductBuyOptions.propTypes = {
  variant: PropTypes.object.isRequired,
  maxQty: PropTypes.number,
  productTitle: PropTypes.string,
};

export default memo(ProductBuyOptions);
